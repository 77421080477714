import { FC, useCallback, useState } from 'react';

import { Link } from '@common/atoms/Link';
import { NavItem, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { PlatformLogo, PositionVariant } from '@web/atoms/PlatformLogo/PlatformLogo';
import { ProTag } from '@web/atoms/ProTag';
import { ComponentList as NavItemList } from '@web/molecules';
import { ExpandableSearchBar } from '@web/molecules/ExpandableSearchBar';
import { PlatformToggle } from '@web/molecules/PlatformToggle';
import { HamburgerIcon } from '@web/organisms/Header/atoms/HamburgerIcon/HamburgerIcon';
import { LanguageSelector } from '@web/organisms/Header/molecules/LanguageSelector/LanguageSelector';
import { getAdminUrl } from '@web/utils/getAdminUrl';

import { UserArea } from '../UserArea/UserArea';

import styles from './Navigation.module.scss';

export interface Props {
    items: NavItem[];
    currentNavItem?: NavItem;
    isInverted?: boolean;
    toggleMenu: () => void;
    currentSearch?: string;
    onSubmitSearch: (searchText: string) => void;
    notificationsCount?: number;
    isLoggedIn?: boolean;
    menuView?: 'mobile' | 'desktop' | '';
    menuOpen?: boolean;
}

interface MenuState {
    expandedSearch: boolean;
}

export const Navigation: FC<Props> = ({
    items,
    currentNavItem,
    isInverted,
    toggleMenu,
    currentSearch = '',
    notificationsCount,
    onSubmitSearch,
    isLoggedIn,
    menuView,
    menuOpen,
}) => {
    const { platform, context } = useContextData();
    const [state, setState] = useState<MenuState>({
        expandedSearch: !!currentSearch,
    });

    const expandSearch = useCallback((isExpanded: boolean) => {
        setState((prevState) => ({ ...prevState, expandedSearch: isExpanded }));
    }, []);

    const { expandedSearch } = state;

    const adminUrl = getAdminUrl(context.url);

    const classes = [styles.Navigation];
    if (menuOpen) classes.push(styles.fullscreen);
    if (!isInverted) classes.push(styles['light-background']);

    return (
        <aside className={classes.join(' ')}>
            <PlatformToggle exclude={[PlatformID.VI]}>
                <span className={styles['hamburger-area']}>
                    <HamburgerIcon isInverted={isInverted} onClick={toggleMenu} />
                </span>
            </PlatformToggle>
            <span className={styles['logo-area']}>
                <InternalLink route={Route.Homepage}>
                    <PlatformLogo positionVariant={PositionVariant.HEADER} platformID={platform.id} />
                </InternalLink>
            </span>
            <UserArea {...{ isLoggedIn, adminUrl: adminUrl, notificationsCount }} />
            <nav
                id="main-nav"
                className={expandedSearch ? styles.collapsed : ''}
                role="navigation"
                aria-label="main-navigation"
            >
                <NavItemList
                    ComponentItem={(item: NavItem) => (
                        <li className={item.id === currentNavItem?.id ? styles['is-active'] : ''}>
                            {item.name === 'pro' ? (
                                <InternalLink route={Route.Premium}>
                                    <ProTag />
                                </InternalLink>
                            ) : (
                                <Link className={styles[item.class ?? item.name]} href={item.url}>
                                    {item.name}
                                </Link>
                            )}
                        </li>
                    )}
                    propsList={items}
                />
            </nav>

            {menuView === 'desktop' ? (
                <PlatformToggle exclude={[PlatformID.GP, PlatformID.BR, PlatformID.VI]}>
                    <ExpandableSearchBar
                        additionalClass={`${styles['search-area']} ${expandedSearch ? styles.expanded : ''}`}
                        currentSearch={currentSearch}
                        onExpand={expandSearch}
                        onSubmit={onSubmitSearch}
                    />
                </PlatformToggle>
            ) : null}
            {menuView === 'mobile' ? (
                <ExpandableSearchBar
                    additionalClass={styles['search-area']}
                    currentSearch={currentSearch}
                    onSubmit={onSubmitSearch}
                />
            ) : null}
            <span className={styles['settings-area']}>
                <PlatformToggle include={[PlatformID.GP]}>
                    <LanguageSelector isInverted />
                </PlatformToggle>
            </span>
        </aside>
    );
};

'use client';

import { FC, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useTranslation } from '@cms/i18n';
import { ModuleScheduleSection, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData/useContextData';
import { QueryUtil } from '@common/utils/QueryUtil';
import { fetchUnreadCountNotifications } from '@web/handlers/fetchNotifications';
import { MediaQuery, MediaQueryDirection, useResponsive } from '@web/hooks/useResponsive/useResponsive';
import { Navigation } from '@web/organisms/Header/molecules/Navigation';
import { SubNavigation } from '@web/organisms/Header/molecules/SubNavigation';
import { useNavItems } from '@web/organisms/Header/useNavItems';
import { formatInternalRoute, Route } from '@web/routing';

import styles from './Header.module.scss';

const excludedRoutes = [Route.Signin, Route.Subscribe];

interface MenuState {
    menuView: 'mobile' | 'desktop' | '';
    currentSearch: string;
    menuOpen: boolean;
    expandedSearch: 'top' | 'bottom' | '';
    notificationsCount: number;
}

type Router = ReturnType<typeof useRouter>;
const getCurrentSearch = (router: Router) => {
    if (router.route === `/${Route.Search}`) {
        return QueryUtil.getQueryParam(router.query, 'q') || '';
    }
    return '';
};

export const Header: FC = () => {
    const router = useRouter();
    const contextData = useContextData();
    const __url = useTranslation('url').t;
    const [menuState, setMenuState] = useState<MenuState>({
        menuView: '',
        currentSearch: getCurrentSearch(router),
        menuOpen: false,
        expandedSearch: '',
        notificationsCount: 0,
    });

    const [isLoading, setLoading] = useState(true);
    const { currentNavItem, mainItems, nonMainItems, subItems, allItems } = useNavItems(menuState.menuOpen);

    useEffect(() => {
        const fetchAndUpdateNotifications = async () => {
            if (isLoading) {
                const _notificationsCount: number =
                    (await fetchUnreadCountNotifications(contextData, true))?.count || 0;

                setMenuState((prevState) => ({
                    ...prevState,
                    notificationsCount: _notificationsCount,
                }));
                setLoading(false);
            }
        };
        fetchAndUpdateNotifications();
    }, [contextData, isLoading]);

    const isBreakpointLarge = useResponsive({
        direction: MediaQueryDirection.above,
        size: MediaQuery.l,
    });

    useEffect(() => {
        const setMenuView = (view: MenuState['menuView']) => {
            setMenuState((prevState: MenuState) => ({
                ...prevState,
                menuView: view,
            }));
        };

        return isBreakpointLarge ? setMenuView('desktop') : setMenuView('mobile');
    }, [isBreakpointLarge]);

    const toggleMenu = useCallback(() => {
        setMenuState((prevState: MenuState) => ({
            ...prevState,
            menuOpen: !prevState.menuOpen,
            expandedSearch: '',
        }));
    }, []);

    const submitSearch = useCallback(
        (searchText: string) => {
            const formattedRoute = formatInternalRoute(Route.Search, contextData.context.slug, __url);
            // router.push doesn't work due to glitch in Next.js: https://github.com/vercel/next.js/issues/22325
            window.location.href = `${formattedRoute}?q=${encodeURIComponent(searchText)}`;
        },
        [__url, contextData.context.slug],
    );

    if (contextData.route === Route.Signin || contextData.isMobileApp) {
        return null;
    }

    const { menuView, menuOpen, currentSearch, notificationsCount } = menuState;

    const isLoggedIn = !!contextData.userID;

    const classes = [styles.Header];
    const isInverted = ![PlatformID.BR, PlatformID.VI].includes(contextData.platform.id);

    const modulesBySection = contextData.modulesBySection;
    const isNationalTheme = modulesBySection?.[ModuleScheduleSection.NATIONAL_THEME]?.[0];

    if (contextData.route && excludedRoutes.includes(contextData.route)) {
        return null;
    }

    if (menuOpen) {
        classes.push(styles['full-screen']);
    }

    return (
        <header id="head" className={classes.join(' ')}>
            {/* TOP  MENU */}
            <section className={`${styles.top} ${isNationalTheme ? styles['national-theme'] : ''}`}>
                <div className={`full-width ${styles['top-container']}`}>
                    <Navigation
                        items={menuOpen ? allItems : mainItems}
                        currentNavItem={currentNavItem}
                        isInverted={isInverted}
                        toggleMenu={toggleMenu}
                        currentSearch={currentSearch}
                        onSubmitSearch={submitSearch}
                        notificationsCount={notificationsCount}
                        isLoggedIn={isLoggedIn}
                        menuView={menuView}
                        menuOpen={menuOpen}
                    />
                </div>
            </section>
            {/* SUB MENU */}
            {!menuOpen ? (
                <section className={styles.bottom}>
                    <div className="full-width">
                        <SubNavigation
                            items={
                                [PlatformID.VI, PlatformID.BR, PlatformID.GP].includes(
                                    contextData.platform.id,
                                )
                                    ? subItems
                                    : nonMainItems
                            }
                            currentSearch={currentSearch}
                            onSubmitSearch={submitSearch}
                            currentNavItem={currentNavItem}
                            menuView={menuView}
                        />
                    </div>
                </section>
            ) : null}
        </header>
    );
};

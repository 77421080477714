import { FC } from 'react';

import { Icon } from '@common/atoms/Icon';
import { Link } from '@common/atoms/Link';
import { PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { Avatar } from '@web/atoms';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { IconWithCounter } from '@web/molecules/IconWithCounter';
import { PlatformToggle } from '@web/molecules/PlatformToggle';
import { LoginButton } from '@web/organisms/Header/atoms/LoginButton/LoginButton';
import { hasElevatedAccess } from '@web/utils/userRole';

import styles from './UserArea.module.scss';

export interface Props {
    isLoggedIn?: boolean;
    adminUrl: string;
    notificationsCount?: number;
}

export const UserArea: FC<Props> = ({ isLoggedIn, adminUrl, notificationsCount }) => {
    const { user } = useContextData();

    return (
        <div className={styles.UserArea}>
            {isLoggedIn ? (
                <>
                    {/* Add link to admin if user is an admin */}
                    {hasElevatedAccess(user?.role) ? (
                        <Link href={adminUrl} passHref={true}>
                            <Icon.admin />
                        </Link>
                    ) : null}
                    <InternalLink route={Route.Notifications}>
                        <IconWithCounter
                            icon={<Icon.bell />}
                            badgeText={notificationsCount}
                            badgeBackground={'second'}
                        />
                    </InternalLink>
                    <InternalLink key="profile" route={Route.Profile}>
                        <Avatar src={user?.image} isRounded={true} isSmall />
                    </InternalLink>
                </>
            ) : (
                <PlatformToggle exclude={[PlatformID.BR]}>
                    <LoginButton />
                </PlatformToggle>
            )}
        </div>
    );
};

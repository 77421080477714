import { FC } from 'react';
import type { Organization, WithContext } from 'schema-dts';

import { useTranslation } from '@cms/i18n';
import { useContextData } from '@common/useContextData';
import { formatRoute, Route } from '@web/routing';

import { StructuredData } from './atoms/StructuredData';

export const OrganizationStructuredData: FC = () => {
    const { context, platform, env } = useContextData();
    const __url = useTranslation('url').t;
    const path = formatRoute(context, Route.Homepage, __url);
    const publicAssetPrefix = env.NEXT_PUBLIC_ASSET_PREFIX;

    const data: WithContext<Organization> = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        '@id': path,
        name: platform.name || '',
        url: path,
        logo: {
            '@type': 'ImageObject',
            url: `${publicAssetPrefix}/images/${platform.id}/logo-dark.svg`,
        },
        location: {
            '@type': 'Place',
            name: platform.name || '',
            address: context.address || '',
        },
    };

    const sameAs: string[] = [];
    if (context.twitter) sameAs.push('https://twitter.com/' + context.twitter);
    if (context.facebook) sameAs.push('https://facebook.com/' + context.facebook);
    if (context.instagram) sameAs.push('https://instagram.com/' + context.instagram);
    if (context.tiktok) sameAs.push('https://tiktok.com/' + context.tiktok);
    if (context.youtube) sameAs.push('https://youtube.com/' + context.youtube);
    if (sameAs.length) data.sameAs = sameAs;

    return <StructuredData data={data} entity={'Organization'} />;
};

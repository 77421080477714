import { FC, PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';

import { ContextProviders } from '@common/atoms/ContextProviders';
import { AdPlacement } from '@common/clients/api';
import { ContextHash, ResolvedContextData } from '@common/defaults';
import { AccountIntroduction } from '@web/molecules/AccountIntroduction/ClientSideAccountIntroduction';
import { Ad } from '@web/molecules/Ad';
import { OrganizationStructuredData } from '@web/molecules/StructuredData';
import { TrackingHandler } from '@web/molecules/TrackingHandler';
import { Header } from '@web/organisms/Header';
import { Route } from '@web/routing';

import { getTheme } from '../getTheme';

const Footer = dynamic(() => import('@web/organisms/Footer').then((module) => module.Footer));
const FontFaces = dynamic(() =>
    import('@common/atoms/FontFaces/pages-router').then((module) => module.FontFaces),
);

const ResponsiveIndicator = dynamic(() =>
    import('@web/atoms/ResponsiveIndicator').then((module) => module.ResponsiveIndicator),
);

export interface Props extends PropsWithChildren {
    contextData: ResolvedContextData;
    contextHash: ContextHash;
    isAppRouter: boolean;
}

export const Body: FC<Props> = ({ contextHash, contextData, children, isAppRouter }) => {
    const { platformID, hostname, isDarkWeb } = contextHash;
    const theme = getTheme(contextData, hostname, platformID);

    return (
        <>
            <ContextProviders contextData={contextData} theme={theme}>
                {isAppRouter ? null : <FontFaces platform={contextHash.platformID} />}
                <Header />
                <main>
                    <div className="full-width">
                        {contextData.allowAds && contextData.route !== Route.Homepage ? (
                            <Ad isOptional placement={AdPlacement.NON_HOME_BEFORE_CONTENT} />
                        ) : null}
                        {children}
                    </div>
                </main>
                {isDarkWeb ? null : <Footer />}
                <AccountIntroduction />
                {contextData.context ? <OrganizationStructuredData /> : null}
                <TrackingHandler />
            </ContextProviders>
            {contextData.env.APP_ENV === 'development' ? <ResponsiveIndicator /> : null}
        </>
    );
};

import { FC, memo, ReactElement, ReactNode, useMemo } from 'react';

import { useTranslation } from '@cms/i18n';
import { Header as WidgetHeader } from '@web/atoms/Header';
import { ComponentList as WidgetItemList } from '@web/molecules/ComponentList';
import { WidgetTitle } from '@web/molecules/Widget';
import Widget from '@web/molecules/Widget/Widget';
import styles from '@web/molecules/Widget/Widget.module.scss';

interface Props<T> {
    isTabbedWidget?: boolean;
    ComponentItem: FC<T>;
    dataList?: T[];
    metaSuffix?: string;
    metaIcon?: ReactElement;
    title: string;
    titleClasses?: string;
    isPremium?: string;
    renderHeader?: () => ReactNode;
    renderFooter?: () => ReactNode;
    adIndex?: number;
}

/**
 * SimpleWidget is a widget organism/template that is used to render a sidebar component
 * with a title, header, and a list of ComponentItem(s).
 * @param {Object} props - The props object.
 * @param {boolean} props.isTabbedWidget - If true, the widget will render without a title and header.
 * @param {FC<T>} props.ComponentItem - A listable component like NewsBlockItem.
 * @param {T[]} props.dataList - If provided, the widget will render with fetched data (DataFetcher).
 * @param {() => ReactNode} props.renderHeader - Add JSX to the header, e.g., extra buttons.
 * @param {string} props.title - A title for the widget.
 * @param {string} props.titleClasses - A class for the title.
 * @returns {JSX.Element}
 */
const WidgetBase = <T,>({
    isTabbedWidget,
    ComponentItem,
    dataList = [],
    metaIcon,
    metaSuffix,
    title,
    titleClasses,
    isPremium,
    renderHeader,
    renderFooter,
    adIndex,
}: Props<T>): JSX.Element => {
    const __sidebar = useTranslation('sidebar').t;
    const highlightedTabData = useMemo(
        () => ({
            metaSuffix: metaSuffix ? __sidebar(metaSuffix) : '',
            metaIcon: metaIcon,
        }),
        [__sidebar, metaIcon, metaSuffix],
    );

    return isTabbedWidget ? (
        <WidgetItemList
            classes={styles['Widget-list']}
            ComponentItem={(props: T) => (
                <ComponentItem adIndex={adIndex} {...props} {...highlightedTabData} />
            )}
            propsList={dataList}
        />
    ) : (
        <Widget classes={isPremium ? styles['Widget-premium'] : ''}>
            <WidgetHeader classes={styles['Widget-header']} flexDirection="row">
                <WidgetTitle
                    heading={__sidebar(title)}
                    classes={titleClasses ? styles[titleClasses] : styles['border-third']}
                />
                {renderHeader ? renderHeader() : null}
            </WidgetHeader>
            <WidgetItemList
                classes={styles['Widget-list']}
                ComponentItem={(props: T) => (
                    <ComponentItem adIndex={adIndex} {...props} {...highlightedTabData} />
                )}
                propsList={dataList}
            />
            {renderFooter ? renderFooter() : null}
        </Widget>
    );
};

/**
 *  This type assertion is needed to avoid use of the generic SimpleWidget to throw
 *  errors passing in a generic ComponentItem prop.
 *  @returns {JSX.Element} - Memoized SimpleWidget.
 */
export const SimpleWidget = memo(WidgetBase) as typeof WidgetBase;

const axios = require('axios');
const limiter = require('limiter');

let rateLimiter = false;

const tryCreatingMissingKey = async (namespace, key) => {
    console.info(`[i18n] Trying to fetch "${key}" from Lokalise to add to "${namespace}".`);

    if (!rateLimiter) rateLimiter = new limiter.RateLimiter({ tokensPerInterval: 1, interval: 'second' });

    const data = {
        file: namespace,
        key: key,
    };

    try {
        await rateLimiter.removeTokens(1);
        const response = await axios({
            data,
            method: 'POST',
            url: process.env.NEXT_SERVER_API_HOST_LOCAL_VP + '/lokalise/key',
            mediaType: 'application/json',
        });

        console.warn(`[i18n] result:`, response.data);
    } catch (error) {
        console.error(`[i18n] error:`, error);
    }
};

exports.tryCreatingMissingKey = tryCreatingMissingKey;

import fs from 'fs';
import path from 'path';

type NamespacesFile = {
    defaultNamespaces: string[];
    routeNamespaces: Record<string, string[]>;
};

/**
 * @name getNamespacesByRoute
 * @description Get the namespaces by route path using the namespacesPerPage object
 * @param route
 * @param namespacesPerPage
 */
export const getNamespacesByRoute = (
    route: string | undefined,
    { defaultNamespaces, routeNamespaces }: NamespacesFile,
) => {
    if (!route) {
        return defaultNamespaces;
    }

    routeNamespaces[route] ??= [];

    return [...defaultNamespaces, ...routeNamespaces[route]].flat();
};

/**
 * @name getFallbackNamespaces
 * @description Get the fallback namespaces from the locale/en folder
 * @returns {string[]}
 * @throws {Error}
 * @example
 * import { getFallbackNamespaces } from '@cms/i18n';
 *
 * const namespaces = getFallbackNamespaces(); // ['common', 'footer', 'header', 'home', 'news']
 *
 * @note The function is used to generate the fallback namespaces for the i18next instance.
 */
export const getFallbackNamespaces = () => {
    const files = fs.readdirSync(path.resolve(`${process.cwd()}/../i18n/locale/en`));

    if (!files.length) {
        throw new Error(
            `The locale/en folder is missing in the i18n folder. Please create the folder with the proper namespaces.`,
        );
    }

    const namespaces = files.map((file) => file.replace('.json', ''));

    return namespaces;
};

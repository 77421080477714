import { ModuleScheduleSection, User } from '@common/clients/api';
import { ResolvedContextData } from '@common/defaults/ContextData';
import { SearchParams } from '@common/defaults/SearchParams';
import { DateTimeDuration } from '@common/utils/DateTimeDuration';

const isUserOver24 = (user: User) => {
    const age = new DateTimeDuration(new Date(user.birthDate!));
    return age.years >= 24;
};

export const isBettingDisabledForUser = (user?: User) => {
    const isOver24 = user?.birthDate ? isUserOver24(user) : undefined;
    return isOver24 === false || user?.bettingEnabled === false;
};

type CheckIfBettingEnabled = (
    user: User | Promise<User | undefined>,
    searchParams?: SearchParams,
) => Promise<boolean>;

export const checkIfBettingEnabled: CheckIfBettingEnabled = async (user, searchParams) => {
    return !isBettingDisabledForUser(await user) && searchParams?.betting !== 'false';
};

export const checkIfModuleSectionEnabeled = (
    modulesBySection: ResolvedContextData['modulesBySection'],
): boolean => {
    if (!modulesBySection) return false;
    for (const key of Object.values(ModuleScheduleSection)) {
        if (key === ModuleScheduleSection.NATIONAL_THEME) continue;
        const value = modulesBySection[key];
        if (value?.length) return true;
    }
    return false;
};

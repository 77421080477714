import { FC } from 'react';

import { useTranslation } from '@cms/i18n';
import { PartialTag, PlatformID } from '@common/clients/api';
import { InternalLink } from '@web/atoms/InternalLink';
import { StaticImage } from '@web/atoms/StaticImage';
import { DisplayType } from '@web/molecules/NewsList';
import { RouteByTagType } from '@web/routing';

import styles from './Flag.module.scss';

interface Props {
    displayType?: DisplayType;
    tag: PartialTag;
    platform?: PlatformID;
}

const DefaultFlag: FC = () => (
    <span className={styles.Flag}>
        <StaticImage src={`/images/flags/default.png`} width="13" height="9" alt="" />
    </span>
);

export const Flag: FC<Partial<Props>> = ({ displayType, tag, platform }) => {
    const __meta = useTranslation('meta').t;

    switch (platform) {
        case PlatformID.BR:
        case PlatformID.GP:
            return null;
        default:
            if (tag && displayType === DisplayType.compact && tag.tagType && tag.flag) {
                return (
                    <InternalLink
                        className={styles.Flag}
                        route={RouteByTagType[tag.tagType]}
                        query={tag.tagSlug ? { tag: tag.tagSlug } : undefined}
                    >
                        <StaticImage
                            src={`/images/flags/${tag.flag}.png`}
                            width="13"
                            height="9"
                            alt={`${tag.flag} ${__meta('label.flag', { countryName: tag.flag })}
`}
                            priority={false}
                        />
                    </InternalLink>
                );
            }
            return <DefaultFlag />;
    }
};

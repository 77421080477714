import { LocaleID, PlatformID, SubjectID } from '../clients/api';
import { getRecordEntries } from '../utils/getRecordEntries';

export enum Host {
    BR = `bright|\bbr\b`,
    GP = `gpblog|\bgp\b`,
    VI = `\bvi\b`,
    VN = `voetbalnieuws|\bvn\b`,
    // Note: VP_BE must become before VP
    VP_BE = `voetbalprimeur.be|voetbalprimeurbe|\bvp-?be\b`,
    VP = `voetbalprimeur|\bvp\b`,
}

export const HostRegexByHost: Record<Host, RegExp> = Object.fromEntries(
    Object.values(Host).map((host): [Host, RegExp] => [host, new RegExp(host, 'i')]),
) as Record<Host, RegExp>;

export const DEFAULT_HOST_REGEX = HostRegexByHost[Host.VN];

export const getPlatformIDByHostname = (hostname: string): PlatformID | undefined => {
    const host = getHostByHostname(hostname);
    return host ? PlatformByHost[host] : undefined;
};

export const getHostByHostname = (hostname: string): Host | undefined => {
    for (const [host, regex] of getRecordEntries(HostRegexByHost)) {
        if (regex.test(hostname)) return host;
    }
    return undefined;
};

export const PlatformByHost: Record<Host, PlatformID> = {
    [Host.VN]: PlatformID.VN,
    [Host.VP]: PlatformID.VP,
    [Host.VP_BE]: PlatformID.VP,
    [Host.GP]: PlatformID.GP,
    [Host.BR]: PlatformID.BR,
    [Host.VI]: PlatformID.VI,
};

export const SubjectByPlatform: Record<PlatformID, SubjectID> = {
    [PlatformID.BR]: SubjectID.TECH,
    [PlatformID.GP]: SubjectID.MOTORSPORT,
    [PlatformID.VI]: SubjectID.FOOTBALL,
    [PlatformID.VN]: SubjectID.FOOTBALL,
    [PlatformID.VP]: SubjectID.FOOTBALL,
};

export const LocaleByHost: Record<Host, LocaleID | LocaleID[]> = {
    [Host.VN]: LocaleID.NL_NL,
    [Host.VP]: LocaleID.NL_NL,
    [Host.VP_BE]: LocaleID.NL_BE,
    [Host.GP]: [
        LocaleID.DE,
        LocaleID.EN,
        LocaleID.ES,
        LocaleID.FR,
        LocaleID.IT,
        LocaleID.NL_NL,
        LocaleID.PT_BR,
    ],
    [Host.BR]: LocaleID.NL_NL,
    [Host.VI]: LocaleID.NL_NL,
};

import { FC } from 'react';

import { useTranslation } from '@cms/i18n';

import { Columns, Row } from './types';

import styles from './DetailsBlock.module.scss';

export interface Props {
    columns: Columns;
    data: Row;
}

export const DetailsBlock: FC<Props> = (props) => {
    const __components = useTranslation('components').t;

    const columns = Object.entries(props.columns);
    const length = columns.length % 2 === 1 ? columns.length + 1 : columns.length;
    let i = 0;

    const details = columns.map(([key, column]) => {
        const value = props.data[key] || props.data[key] === 0 ? props.data[key] : '-';
        const title = column.title || __components('DetailsBlock.columns.' + key);

        // odd-nes should reset on the second column
        i++;
        if (i > length / 2) i -= length / 2;
        const isOdd = i % 2 === 1;

        const detailsClasses = [styles.row];
        if (isOdd) detailsClasses.push(styles.odd, 'DetailsBlock_odd');

        return (
            <div key={key} className={detailsClasses.join(' ')}>
                <dt>{title}</dt>
                <dd>{value}</dd>
            </div>
        );
    });

    return (
        <dl className={styles.DetailsBlock}>
            {details}
            {columns.length % 2 === 1 ? <div /> : ''}
        </dl>
    );
};

import { User, UserService } from '@common/clients/api';
import { ApiBaseRequest, PartialContextData } from '@common/clients/request/ApiBaseRequest';
import { defaultCatch } from '@common/defaults/defaultCatch';

export const fetchCurrentUserProfile = async (
    contextData: PartialContextData,
    isClientSide: boolean = false,
): Promise<User | undefined> => {
    const request = new ApiBaseRequest(contextData, isClientSide);
    const userService = new UserService(request);

    return await userService.getProfileByIdUser().catch(defaultCatch);
};

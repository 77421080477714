import { GrandPrix, GrandPrixService } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request/ApiBaseRequest';
import { ContextData, defaultCatch } from '@common/defaults';

const MAX_DAYS_UNTIL_NEXT_GP = 14;

export const fetchUpcomingGrandPrix = async (
    contextData: ContextData,
    isClientSide: boolean = true,
): Promise<GrandPrix | undefined> => {
    const request = new ApiBaseRequest(contextData, isClientSide);
    const gpService = new GrandPrixService(request);
    const response = await gpService
        .upcomingGrandPrixByDomainId({
            contextId: contextData.context.id,
            days: MAX_DAYS_UNTIL_NEXT_GP,
        })
        .catch(defaultCatch);

    return response;
};

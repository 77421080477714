import { FC, useCallback, useState } from 'react';

import { useTranslation } from '@cms/i18n';
import { ModuleScheduleSection, News } from '@common/clients/api';
import { ContextData } from '@common/defaults';
import { ItemsPerComponent } from '@common/types/ItemsPerComponent';
import { useContextData } from '@common/useContextData';
import { DateTimeUtil, Format, formatNumericDuration } from '@common/utils/DateTimeUtil';
import { fetchHomepageNews } from '@web/handlers/fetchHomepageNews';
import { NewsBlockItem, NewsBlockItemProps, Variant } from '@web/molecules/NewsBlockItem';
import { ReadMoreButton } from '@web/molecules/NewsList/ReadMoreButton';
import { WidgetType } from '@web/organisms/Sidebar/types';
import { SimpleWidget } from '@web/organisms/SimpleWidget';
import { SimpleWidgetConfig } from '@web/organisms/SimpleWidget/types';
import { Route } from '@web/routing';

import { FetcherProps } from './types';

interface Props {
    dataList?: NewsBlockItemProps<Variant.TIMELINE | Variant.AD>[];
    handleFetchMore?: () => Promise<void>;
    adIndex?: number;
}

export const widgetConfig: SimpleWidgetConfig = {
    title: 'recent-news.title',
    metaKey: WidgetType.RECENT_NEWS,
};

const fetchRecentNews = async (contextData: ContextData, isClientSide: boolean = false) => {
    const isHomepage = contextData?.route === Route.Homepage;
    const perComponent = isHomepage ? ItemsPerComponent.HUGE : ItemsPerComponent.STANDARD;
    const response = await fetchHomepageNews(contextData, 1, isClientSide, perComponent);
    return response?.data || [];
};

export const mapFetchedNews = (data: News[] = []): Props['dataList'] =>
    data.map(
        ({ category = {}, image = '', newsDate, newsTitle: title = '', host, path, ...newsItem }, index) => {
            const url = host && newsItem ? `${host}${path}` : '';
            const date = DateTimeUtil.format(new Date(newsDate), Format.TIME_NUMERIC);
            const videoDuration =
                newsItem?.videofeedItem && newsItem.videofeedItem.duration
                    ? formatNumericDuration(newsItem.videofeedItem.duration)
                    : undefined;
            return {
                index,
                styleVariant: Variant.TIMELINE,
                category: {
                    id: index,
                    ...category,
                },
                url,
                image,
                date,
                title,
                videoDuration,
            };
        },
    );

export const Widget: FC<Props> = ({ adIndex, dataList, handleFetchMore }) => {
    const __sidebar = useTranslation('sidebar').t;
    const displayShowMore = !!(dataList && dataList?.length > 0) && !!handleFetchMore;

    const { modulesBySection } = useContextData();
    const promotedArticle = modulesBySection?.[ModuleScheduleSection.PROMOTED_ARTICLE]?.[0];

    const ad = {
        index: promotedArticle && adIndex ? adIndex + 1 : adIndex,
        styleVariant: Variant.AD,
        title: 'HOME_SIDEBAR_AFTER_SECTION_1',
        url: '',
    };

    const formatPromo = promotedArticle
        ? {
              date: dataList?.[0]?.date || '',
              index: 1,
              styleVariant: Variant.TIMELINE,
              title: promotedArticle.text || '',
              url: promotedArticle.url || '',
              tag: promotedArticle.tag,
              module: promotedArticle.module,
              bookmaker: promotedArticle.bookmaker,
          }
        : undefined;

    const addItemAtIndex = (list: any[], item: any, index: number) =>
        index >= 0 && index <= list.length ? [...list.slice(0, index), item, ...list.slice(index)] : list;

    const listWithPromo = dataList?.length
        ? promotedArticle
            ? addItemAtIndex(dataList, formatPromo, formatPromo!.index)
            : dataList
        : [];

    const adList = dataList && ad.index && addItemAtIndex(listWithPromo, ad, ad.index);

    return (
        <SimpleWidget
            {...widgetConfig}
            ComponentItem={NewsBlockItem}
            dataList={adList || dataList}
            titleClasses={'border-second'}
            adIndex={adIndex}
            renderFooter={() =>
                displayShowMore ? (
                    <ReadMoreButton
                        label={__sidebar('recent-news.read-more')}
                        onClick={() => {
                            handleFetchMore ? handleFetchMore() : undefined;
                        }}
                    />
                ) : null
            }
        />
    );
};

export const WidgetWithFetch: FC<Props> = ({ dataList }) => {
    const contextData = useContextData();

    const [fetchState, setFetchState] = useState({
        page: 1,
        hasNextPage: true,
        data: dataList || [],
    });

    const handleFetchMore = useCallback(async () => {
        if (!fetchState.hasNextPage) return;

        const page = fetchState.page + 1;
        const news = await fetchHomepageNews(
            contextData,
            page,
            true,
            dataList?.length || ItemsPerComponent.STANDARD,
        );
        const data = news?.data || [];
        const hasNextPage = news?.pagination?.hasNextPage || false;

        setFetchState((prevState) => ({
            ...prevState,
            page,
            hasNextPage,
            data: [...prevState.data, ...(mapFetchedNews(data) || [])],
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextData, fetchState]);

    return <Widget dataList={fetchState.data} handleFetchMore={handleFetchMore} />;
};

export const fetcherProps: FetcherProps<News[], Props['dataList']> = {
    fetcher: fetchRecentNews,
    mapper: mapFetchedNews,
    storageKey: WidgetType.RECENT_NEWS,
};

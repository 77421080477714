import { FC, Fragment, ReactElement } from 'react';

import { AdPlacement, PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { Ad } from '@web/molecules/Ad';
import { SidebarData, SidebarSequenceWidgets, WidgetType } from '@web/organisms/Sidebar/types';
import { SidebarSequence } from '@web/routing/types/SidebarSequenceByRoute';

import { SideWidget } from '../SideWidgets/SideWidget';

import styles from './Sidebar.module.scss';

/**
 * Configure which widgets are included for each platform and sequence.
 */
// prettier-ignore
export const PLATFORM_SIDEBAR_WIDGETS: Record<PlatformID, SidebarSequenceWidgets> = {
    [PlatformID.BR]: {
        [SidebarSequence.DEFAULT]: [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS
        ],
        [SidebarSequence.PREMIUM_HOMEPAGE]: [],
        [SidebarSequence.RIGHT_COL_PODCAST]: [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED
        ],
        [SidebarSequence.RIGHT_COL_HOMEPAGE]: [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA]: [
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS
        ],
        [SidebarSequence.RIGHT_COL_MEDIA_INDEX]: [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MISC]: [
            WidgetType.MOST_READ,
            WidgetType.LATEST_PODCASTS
        ],
        [SidebarSequence.RIGHT_COL_ARTICLE]: [
            WidgetType.MOST_READ,
            WidgetType.LATEST_PODCASTS
        ],
    },
    [PlatformID.GP]: {
        [SidebarSequence.DEFAULT]: [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS
        ],
        [SidebarSequence.PREMIUM_HOMEPAGE]: [],
        [SidebarSequence.RIGHT_COL_PODCAST]: [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED
        ],
        [SidebarSequence.RIGHT_COL_HOMEPAGE]: [
            // WidgetType.UPCOMING_GP,
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA]: [
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS
        ],
        [SidebarSequence.RIGHT_COL_MEDIA_INDEX]: [
            WidgetType.MOST_VIEWED,
            WidgetType.MOST_READ,
            WidgetType.MOST_COMMENTED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MISC]: [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_READ,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_ARTICLE]: [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_READ,
            WidgetType.LATEST_PODCASTS,
        ],
    },
    [PlatformID.VI]: {
        [SidebarSequence.DEFAULT]: [WidgetType.RECENT_NEWS],
        [SidebarSequence.PREMIUM_HOMEPAGE]: [WidgetType.PRO_NEWS],
        [SidebarSequence.RIGHT_COL_PODCAST]: [],
        [SidebarSequence.RIGHT_COL_HOMEPAGE]: [WidgetType.RECENT_NEWS],
        [SidebarSequence.RIGHT_COL_MEDIA]: [WidgetType.MOST_VIEWED],
        [SidebarSequence.RIGHT_COL_MEDIA_INDEX]: [],
        [SidebarSequence.RIGHT_COL_MISC]: [WidgetType.RECENT_NEWS],
        [SidebarSequence.RIGHT_COL_ARTICLE]: [
            WidgetType.RECENT_NEWS,
            WidgetType.PRO_NEWS,
            WidgetType.MOST_READ,
        ],
    },
    [PlatformID.VN]: {
        [SidebarSequence.DEFAULT]: [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS
        ],
        [SidebarSequence.PREMIUM_HOMEPAGE]: [],
        [SidebarSequence.RIGHT_COL_PODCAST]: [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED
        ],
        [SidebarSequence.RIGHT_COL_HOMEPAGE]: [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_LIKED_COMMENTS,
            WidgetType.MOST_READ,
            WidgetType.TRANSFER_RUMOUR,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA]: [
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS
        ],
        [SidebarSequence.RIGHT_COL_MEDIA_INDEX]: [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MISC]: [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_LIKED_COMMENTS,
            WidgetType.MOST_READ,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_ARTICLE]: [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_LIKED_COMMENTS,
            WidgetType.MOST_READ,
            WidgetType.LATEST_PODCASTS,
        ],
    },
    [PlatformID.VP]: {
        [SidebarSequence.DEFAULT]: [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS
        ],
        [SidebarSequence.PREMIUM_HOMEPAGE]: [],
        [SidebarSequence.RIGHT_COL_PODCAST]: [
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED
        ],
        [SidebarSequence.RIGHT_COL_HOMEPAGE]: [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_LIKED_COMMENTS,
            WidgetType.MOST_READ,
            WidgetType.TRANSFER_RUMOUR,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA]: [
            WidgetType.MOST_VIEWED,
            WidgetType.TRANSFER_RUMOUR,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MEDIA_INDEX]: [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_READ,
            WidgetType.MOST_VIEWED,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_MISC]: [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_LIKED_COMMENTS,
            WidgetType.MOST_READ,
            WidgetType.TRANSFER_RUMOUR,
            WidgetType.LATEST_PODCASTS,
        ],
        [SidebarSequence.RIGHT_COL_ARTICLE]: [
            WidgetType.MOST_COMMENTED,
            WidgetType.MOST_LIKED_COMMENTS,
            WidgetType.MOST_READ,
            WidgetType.TRANSFER_RUMOUR,
            WidgetType.LATEST_PODCASTS,
        ],
    },
};

// Some types are grouped together because they render the same widget and we only want to render one of them
// prettier-ignore
const LAYOUT: WidgetType[][] = [
    [WidgetType.MOST_VIEWED],
    [WidgetType.MOST_READ, WidgetType.MOST_COMMENTED],
    [WidgetType.TRANSFER_RUMOUR],
    [WidgetType.MOST_LIKED_COMMENTS],
];

const LAYOUT_PER_PLATFORM: Record<PlatformID, WidgetType[][]> = {
    [PlatformID.BR]: [[WidgetType.LATEST_PODCASTS], ...LAYOUT],
    [PlatformID.GP]: [[WidgetType.UPCOMING_GP], ...LAYOUT, [WidgetType.LATEST_PODCASTS]],
    [PlatformID.VI]: [
        [WidgetType.RECENT_NEWS],
        [WidgetType.PRO_NEWS, WidgetType.MOST_READ, WidgetType.MOST_COMMENTED],
        [WidgetType.TRANSFER_RUMOUR],
        [WidgetType.MOST_LIKED_COMMENTS],
        [WidgetType.LATEST_PODCASTS],
    ],
    [PlatformID.VN]: [...LAYOUT, [WidgetType.LATEST_PODCASTS]],
    [PlatformID.VP]: [...LAYOUT, [WidgetType.LATEST_PODCASTS]],
};

const getAdsBySequence = (sequence: SidebarSequence): ReactElement[] => {
    switch (sequence) {
        case SidebarSequence.PREMIUM_HOMEPAGE:
            return [];

        case SidebarSequence.RIGHT_COL_HOMEPAGE:
            return [
                <Ad placement={AdPlacement.HOME_SIDEBAR_TOP} key={AdPlacement.HOME_SIDEBAR_TOP} isOptional />,
                <Ad
                    placement={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_1}
                    key={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_1}
                    isOptional
                />,
                <Ad
                    placement={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_2}
                    key={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_2}
                    isOptional
                />,
            ];
        default:
            return [
                <Ad
                    placement={AdPlacement.NON_HOME_SIDEBAR_TOP}
                    key={AdPlacement.NON_HOME_SIDEBAR_TOP}
                    isOptional
                />,
                <Ad
                    placement={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_1}
                    key={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_1}
                    isOptional
                />,
                <Ad
                    placement={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_2}
                    key={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_2}
                    isOptional
                />,
            ];
    }
};

const filterWidgetsWithData = (
    platformLayout: WidgetType[][],
    sequenceWidgets: WidgetType[],
    sidebarProps: SidebarData,
) => {
    return platformLayout.filter((widgetTypes) =>
        widgetTypes.some((widgetType) => {
            const widgetData = sidebarProps[widgetType];
            const isWidgetWithData = Array.isArray(widgetData)
                ? widgetData.length > 0
                : typeof widgetData === 'object' && widgetData !== null;
            return sequenceWidgets.includes(widgetType) && isWidgetWithData;
        }),
    );
};

interface SidebarProps {
    injections?: React.ReactNode[];
    adIndex?: number;
}

type DirectionProp = SidebarProps & { direction?: 'left' | 'right' };

export type Props = DirectionProp;

export const Sidebar: FC<Props> = ({ adIndex, injections, ...props }) => {
    const alignmentClass = props.direction === 'right' ? styles['right-side'] : styles['left-side'];

    const { platform, sidebarProps } = useContextData();
    if (!sidebarProps) {
        return null;
    }

    const platformLayout = LAYOUT_PER_PLATFORM[platform.id];
    const sequenceWidgets = PLATFORM_SIDEBAR_WIDGETS[platform.id][sidebarProps.sequence];
    const widgetsWithData = filterWidgetsWithData(platformLayout, sequenceWidgets, sidebarProps);

    const Ads = getAdsBySequence(sidebarProps.sequence);
    const totalWidgets = widgetsWithData.length;

    const injectionsToInject = injections?.length ? [...injections] : [];
    const InjectionsOverflow = () => {
        return (
            <>
                {platform.id === PlatformID.VI && adIndex ? Ads.slice(1) : Ads.shift()}
                {injectionsToInject?.map((Injection, index) => (
                    <Fragment key={`InjectionsOverflow-${index}`}>
                        {Injection}
                        {Ads.shift()}
                    </Fragment>
                ))}
            </>
        );
    };

    return (
        <aside className={`${styles.Sidebar} ${alignmentClass} sidebar no-match-ticker`}>
            {!widgetsWithData.length ? (
                !injectionsToInject.length ? (
                    <>{Ads.shift()}</>
                ) : (
                    <InjectionsOverflow key={'first'} />
                )
            ) : (
                widgetsWithData.map((widget: WidgetType[], index: number) => {
                    const isLastWidget = index + 1 === totalWidgets;
                    return (
                        <Fragment key={`widgetsWithData-${index}`}>
                            {injectionsToInject.length ? (
                                <>
                                    {injectionsToInject.shift()}
                                    {Ads.shift()}
                                </>
                            ) : (
                                Ads.shift()
                            )}
                            <SideWidget
                                platformID={platform.id}
                                key={widget[0]}
                                widgetType={widget[0]}
                                sidebarProps={sidebarProps}
                                adIndex={adIndex}
                            />
                            {isLastWidget ? <InjectionsOverflow key={'last'} /> : null}
                        </Fragment>
                    );
                })
            )}
        </aside>
    );
};

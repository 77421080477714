import { ExperimentGroupOrRobots, LocaleID, PlatformID } from '@common/clients/api';
import { invertRecord } from '@common/utils/invertRecord';

/**
 * We add a contextHash to path so we can cache the layouts and pages properly
 * Do not add something to this without thinking, since each unique combination will need it's own cache entry
 * Also, even though it's called a hash, it's important we can decode it, so actual hashing is not allowed
 */
export interface ContextHash {
    hostname: string;
    locale: LocaleID;
    platformID: PlatformID;
    contextID: number;
    allowAds: false | 'preview' | undefined;
    allowBetting: false | 'preview' | undefined;
    allowTracking: false | undefined;
    cacheBuster: number | undefined;
    experimentGroup: ExperimentGroupOrRobots;
    isDarkWeb: true | undefined;
    isMobileApp: true | undefined;
    isPlatformContext: true | undefined;
    isLiveness: true | undefined;
}

export const isContextHash = (x: ContextHash | any): x is ContextHash => !!x?.hostname;

const SEPARATOR = '*';
const KEY_VALUE_SEPARATOR = '~';

const shortKeyByKey: Record<keyof ContextHash, string> = {
    hostname: 'h',
    locale: 'l',
    platformID: 'p',
    contextID: 'c',
    allowAds: 'a',
    allowBetting: 'b',
    allowTracking: 't',
    cacheBuster: 'cb',
    experimentGroup: 'e',
    isDarkWeb: 'dw',
    isMobileApp: 'm',
    isPlatformContext: 'pc',
    isLiveness: 'li',
};
const keyByShortKey = invertRecord(shortKeyByKey);

if (Object.keys(shortKeyByKey).length !== Object.keys(keyByShortKey).length) {
    throw Error(`ContextHash: There is overlap in shortkeys ${JSON.stringify(shortKeyByKey)}`);
}

export const encodeContextHash = (contextHash: ContextHash): string =>
    (Object.keys(shortKeyByKey) as (keyof ContextHash)[])
        .map((key): string => {
            let value = contextHash[key];
            if (value === undefined) {
                return '';
            }

            if (
                typeof value === 'string' &&
                value.indexOf(SEPARATOR) - value.indexOf(KEY_VALUE_SEPARATOR) !== 0
            ) {
                throw Error(`${key}:${value} cannot be serialized`);
            }

            if (value === false) {
                value = 'n';
            } else if (value === true) {
                value = 'y';
            }
            return `${shortKeyByKey[key]}${KEY_VALUE_SEPARATOR}${value}`;
        })
        .filter((value) => value && value !== '')
        .join(SEPARATOR);

export const decodeContextHash = (contextHash: string): ContextHash =>
    Object.fromEntries(
        decodeURIComponent(contextHash)
            .split(SEPARATOR)
            .map((keyvalue) => {
                const [shortKey, value] = keyvalue.split(KEY_VALUE_SEPARATOR);
                const key = keyByShortKey[shortKey];

                let finalValue: string | number | boolean = value;
                if (value === 'y') finalValue = true;
                else if (value === 'n') finalValue = false;
                else if (value && !isNaN(+value)) finalValue = +value;

                return [key, finalValue];
            }),
    ) as unknown as ContextHash;

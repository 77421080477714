/**
 * Since we can't switch to the app router yet, this is a fallback file for the pages router
 * See https://pxr-tech.atlassian.net/browse/PB-6306 for more details
 */

import { prepareProps } from '@web/_app/[contextHash]/videos/prepareProps';
import { Route } from '@web/routing';
import { getPagesStaticPaths, getPagesStaticPropsWrapper } from '@web/routing/getPagesStaticPropsWrapper';
import { Props, VideoOverview } from '@web/templates/VideoOverview';

export const getStaticPaths = getPagesStaticPaths;
export const getStaticProps = getPagesStaticPropsWrapper<Props>(prepareProps, Route.VideoOverview);

export default VideoOverview;

import { FC, useMemo } from 'react';
import { ParsedUrlQueryInput } from 'node:querystring';

import { useTranslation } from '@cms/i18n';
import { Link, Props as LinkProps } from '@common/atoms/Link';
import { useContextData } from '@common/useContextData';
import { formatInternalRoute, Route } from '@web/routing';

export interface Props extends Omit<LinkProps, 'href'> {
    route: Route;
    query?: ParsedUrlQueryInput;
    className?: string;
}

// TODO: place somewhere else if we need more logic like this
const IS_APP_ROUTER = false;

export const useInternalRoute = (route: Route, query?: ParsedUrlQueryInput) => {
    const { context } = useContextData();
    const __url = useTranslation('url').t;
    const pathname = useMemo(
        () => formatInternalRoute(route, context.slug, __url, IS_APP_ROUTER ? query : undefined),
        [route, context.slug, __url, query],
    );
    return { pathname };
};

export const InternalLink: FC<Props> = ({ route, query, children, ...otherProps }) => {
    const { pathname } = useInternalRoute(route, query);

    if (IS_APP_ROUTER) {
        return (
            <Link
                href={{
                    pathname,
                }}
                {...otherProps}
            >
                {children}
            </Link>
        );
    }

    return (
        <Link
            href={{
                pathname,
                query,
            }}
            {...otherProps}
        >
            {children}
        </Link>
    );
};

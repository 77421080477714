import { FC } from 'react';
import Link from 'next/link';

import { useTranslation } from '@cms/i18n';
import { Icon } from '@common/atoms/Icon';
import { PlatformID } from '@common/clients/api';
import { useContextData } from '@common/useContextData/useContextData';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { PlatformToggle } from '@web/molecules/PlatformToggle';

import styles from './LoginButton.module.scss';

export interface Props {}

// FIXME: find a better place to store this array
const PLATFORMS_WITH_SUBSCRIPTIONS = [PlatformID.VI];

export const LoginButton: FC<Props> = () => {
    const __meta = useTranslation('meta').t;
    const { isDarkWebCall } = useContextData();

    return (
        <span className={styles.LoginButton}>
            <InternalLink
                className={styles['center-wrapper']}
                route={isDarkWebCall ? Route.Login : Route.Signin}
            >
                <Icon.userCircle />
                <span className={styles['label-login']}>{__meta('label.login')}</span>
            </InternalLink>
            <PlatformToggle include={PLATFORMS_WITH_SUBSCRIPTIONS}>
                <Link className={styles['subscribe-button']} href={__meta('url:subscribe')}>
                    {__meta('label.subscribe')}
                </Link>
            </PlatformToggle>
        </span>
    );
};

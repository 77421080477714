import { FC, ReactElement, ReactNode, RefObject, useEffect, useRef } from 'react';

import { useTranslation } from '@cms/i18n';
import { Icon, IconOption } from '@common/atoms/Icon';
import { Pagination } from '@common/clients/api';
import { NavArrow } from '@web/atoms/NavArrow';
import { Props as NewsListProps } from '@web/molecules/NewsList/NewsList';
import { ReadMoreButton } from '@web/molecules/NewsList/ReadMoreButton/ReadMoreButton';

import styles from './NewsList.module.scss';

export interface Props {
    defaultProps: NewsListProps;
    items: ReactElement[];
    isHighlighted: boolean;
    newsCount: number;
    firstElementRef?: RefObject<HTMLLIElement>;
    onReadMoreClick?: (pagination: Pagination | undefined) => void;
    moreArticlesLink?: {
        text: string;
        url: string;
    };
}

const arrowClicked = (isNext: boolean, listRef: RefObject<HTMLUListElement>) => {
    if (!listRef.current) return;
    const rect = listRef.current.getBoundingClientRect();
    let width = rect.right - rect.left;
    const leftScroll = listRef.current.scrollLeft;

    //To scroll right when the prev button is clicked
    if (!isNext) width *= -1;

    listRef.current.scrollTo({
        left: leftScroll + width,
        behavior: 'smooth',
    });
};

const getItemsPosition = (firstElementRef?: RefObject<HTMLLIElement>) => {
    if (!firstElementRef?.current) return 0;
    return firstElementRef.current.getBoundingClientRect().left || 0;
};

export const CarrouselWrapper: FC<Props> = ({
    isHighlighted,
    items,
    newsCount,
    firstElementRef,
    moreArticlesLink,
}) => {
    const onShowMoreClick = (url: string) => {
        if (url.length > 0) window.location.href = url;
    };

    const __newsList = useTranslation('newslist').t;
    const listRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        const swipStartPosition = getItemsPosition(firstElementRef);
        const diffMargin = 20;

        const onScrollCheck = () => {
            let firstLoad = true;
            const swipeCurrentPosition = getItemsPosition(firstElementRef);
            const swipingDiff: number = swipStartPosition - swipeCurrentPosition;

            // In order to improve the performance, evaluate the active featured-item every 20px of swiping
            if (
                (firstLoad || Math.abs(swipingDiff) > diffMargin) &&
                firstElementRef?.current &&
                listRef.current
            ) {
                firstLoad = false;
                const listRec = listRef.current?.getBoundingClientRect();
                const listLeftPosition = listRec?.left || 0;
                const listRightPosition = listRec?.right || 0;
                const listWidth = listLeftPosition - listRightPosition;

                const firstRec = firstElementRef?.current?.getBoundingClientRect();
                if (firstRec) {
                    const width = firstRec.right - firstRec.left;
                    const leftPosition = firstRec?.left;

                    if (Math.round((listLeftPosition - leftPosition) / width) === 0) {
                        listRef.current?.classList.add(styles['on-start-position']);
                    } else {
                        listRef.current?.classList.remove(styles['on-start-position']);
                    }

                    if (Math.round((listLeftPosition - leftPosition - listWidth) / width) === newsCount) {
                        listRef.current?.classList.add(styles['on-end-position']);
                    } else {
                        listRef.current?.classList.remove(styles['on-end-position']);
                    }
                }
            }
        };
        listRef.current?.addEventListener('scroll', onScrollCheck);

        // trigger the check one time once the page is loaded.
        onScrollCheck();
    }, [firstElementRef, newsCount]);

    const dividerElement: ReactNode = items.shift();

    return (
        <>
            {/* Render the list header out of the wrapper */}
            {dividerElement}
            <div className={`${styles['carrousel-wrapper']} ${isHighlighted ? 'is-highlighted' : ''}`}>
                <ul className={styles['on-start-position']} ref={listRef}>
                    {items}
                </ul>

                <NavArrow
                    icon={IconOption.leftCenter}
                    onClick={() => arrowClicked(false, listRef)}
                    className={styles['arrow-prev']}
                />

                <NavArrow
                    icon={IconOption.rightCenter}
                    onClick={() => arrowClicked(true, listRef)}
                    className={styles['arrow-next']}
                />

                {isHighlighted ? (
                    <ReadMoreButton
                        icon={<Icon.right />}
                        onClick={() => onShowMoreClick(moreArticlesLink?.url || '')}
                        isHighlighted={isHighlighted}
                        label={__newsList(`more-videos`)}
                    />
                ) : null}
            </div>
        </>
    );
};

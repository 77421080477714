import type { AxiosRequestConfig } from 'axios';

import { BaseHttpRequest, CancelablePromise, OpenAPI } from '@common/clients/api';
import { ApiRequestOptions } from '@common/clients/api/core/ApiRequestOptions';
import { request as __request } from '@common/clients/request/request';
import { ContextData } from '@common/defaults/ContextData';
import { getStatus, SupertokensStatus, updateStatus } from '@common/supertokens';

import { getClient } from './getClient';

const defaultTimeout = Number(process.env.NEXT_PUBLIC_API_TIMEOUT) || 5000;

export type PartialContextData = Pick<ContextData, 'config'> & Partial<ContextData>;

export class ApiBaseRequest extends BaseHttpRequest {
    isClientSide: boolean;
    contextData: PartialContextData;
    axiosOptions: AxiosRequestConfig | undefined;

    constructor(contextData: PartialContextData, isClientSide: boolean, axiosOptions?: AxiosRequestConfig) {
        if (!contextData?.config?.api) throw new Error('contextData.config.api not yet set');

        if (isClientSide) {
            super({
                ...OpenAPI,
                BASE: contextData.config.api.host,
                VERSION: '1.0.0',
                WITH_CREDENTIALS: true,
                CREDENTIALS: 'same-origin',
                HEADERS: {},
            });
        } else {
            const cookies = {
                userID: contextData.userID,
                userSessionID: contextData.userSessionID,
                publishing_session: contextData.magnoliaSessionID,
                sAccessToken: contextData?.supertokens?.sAccessToken,
                sRefreshToken: contextData?.supertokens?.sRefreshToken,
                sFrontToken: contextData?.supertokens?.sFrontToken,
                sAntiCsrf: contextData?.supertokens?.sAntiCsrf,
                'st-last-access-token-update': contextData?.supertokens?.['st-last-access-token-update'],
            } as const;

            super({
                ...OpenAPI,
                BASE: contextData.config.api.internalHost || contextData.config.api.host,
                VERSION: '1.0.0',
                WITH_CREDENTIALS: true,
                CREDENTIALS: 'include',
                HEADERS: {
                    Cookie: Object.entries(cookies)
                        .filter(([_key, value]) => value)
                        .map((entry) => entry.join('='))
                        .join('; '),
                },
            });
        }

        this.isClientSide = isClientSide;
        this.contextData = contextData;
        this.axiosOptions = axiosOptions;
    }

    public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
        const extendedConfig = {
            ...this.config,
            TIMEOUT: defaultTimeout,
        };

        const status = getStatus(this.contextData, this.isClientSide);

        // We know we need a refresh, so avoid doing more calls
        if (status === SupertokensStatus.NEEDS_REFRESH) {
            return new CancelablePromise<T>((_resolve, reject, _onCancel) => {
                reject(new Error('401: Needs Refresh'));
            });
        }

        return new CancelablePromise(async (resolve, reject, onCancel) => {
            try {
                const response = __request<T>(extendedConfig, options, getClient(), this.axiosOptions);
                onCancel(response.cancel);
                const result = await response;
                resolve(result);
            } catch (e) {
                if (typeof e === 'object' && e !== null && 'status' in e && e.status === 401) {
                    updateStatus(this.contextData, this.isClientSide, e.status);
                }
                reject(e);
            }
        });
    }
}
